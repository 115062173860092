/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./Components/redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./Components/extension/i18n";
import { Spinner } from "react-bootstrap";
const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense
    fallback={
      <div className="w-100 vh-100 main_app d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    }
  >
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
